<template>
  <div class="main">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
      <div class="box center mB20 box-bottom-border" style="padding-bottom: 20px;" >
        <el-form-item class="" prop="startTime" label="分析时段">
          <el-date-picker placeholder="请选择时间" @change="clearTable" type="datetime" :clearable="false" format="yyyy-MM-dd HH:mm" value-format="yyyy-MM-dd HH:mm" v-model="ruleForm.startTime"> </el-date-picker>
        </el-form-item>
        <span class="line">-</span>
        <el-form-item prop="endTime">
          <el-date-picker @change="clearTable" placeholder="请选择时间" type="datetime" :clearable="false" format="yyyy-MM-dd HH:mm" value-format="yyyy-MM-dd HH:mm" v-model="ruleForm.endTime"> </el-date-picker>
        </el-form-item>
        <el-button @click="querryData" class="mL44 dataQuery">查询</el-button>
      </div>
      <!-- <el-form-item class="mB20" label="监测类型" prop="type">
        <el-select class="typeSelect" @change="changeType" v-model="ruleForm.range">
          <el-option
              v-for="item in rangeList"
              :key="item.name"
              :label="item.name"
              :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="mB20" label="监测指标" prop="quota">
        <el-radio-group @change="clearTable" v-model="ruleForm.quota">
          <el-radio v-for="item of quotaList" :label="item.id">{{item.name}}</el-radio>
        </el-radio-group>
      </el-form-item> -->
    </el-form>

    <div class="noDataMain" v-if="!tableData.length">
      <img src="../../../assets/images/noData.png" alt="" />
      <span>暂无数据</span>
    </div>

    <template v-if="tableData.length > 0">
      <div class="box-bottom-border" style="padding-bottom: 20px;">
      <el-table :row-class-name="tableRowClassName" :data="tableData" tooltip-effect="dark" style="width: 100%" v-loading="loading" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
        <el-table-column align="center" prop="target.code" label="测点编号"></el-table-column>
        <el-table-column align="center" prop="target.site" label="位置描述">
          <template slot-scope="scope">{{ scope.row.target.site || "—" }}</template>
        </el-table-column>
        <el-table-column align="center" prop="id" label="操作">
          <template slot-scope="scope">
            <span @click="look(scope.row.target)" class="pointer operate-txt">查看数据</span>
          </template>
        </el-table-column>
        <template slot="empty">
          <span class="iconfont iconzanwushuju-"></span>
          <span>暂无数据</span>
        </template>
      </el-table>
      <common-pagination class="pageMain" :hiddenTotal="hiddenTotal" :pageSize="pageSize" :total="total" :currentPage="currentPage" @handleCurrentChange="handleCurrentChange" />
    </div>
    </template>
    <div class="fs16 box center mT20 mB20">
        <img style="margin-right: 12px;width: 14px;height: 14px;" src="@/assets/images/fragment-title-icon.png" />
        <div style="margin-right: 20px;">测点编号：{{ targetName }}</div>
      </div>
    <template v-if="isShowMain">
      <div class="mt14 newBox" v-show="!showNoData1">
          <div class="fs14 title txtCenter">原始数据</div>
          <div class="myChart" id="myChartDynamic"></div>
      </div>
      <div class="noDataMain" v-show="showNoData1">
          <p class="emColor mB10">原始数据</p>
          <img src="../../../assets/images/noData.png" alt="" />
          <span>暂无数据</span>
      </div>

      <div class="mt14 newBox" v-show="!showNoData2">
          <div class="fs14 title txtCenter">动位移</div>
          <div class="myChart" id="myChartDynamic1"></div>
      </div>
      <div class="noDataMain" v-show="showNoData2">
          <p class="emColor mB10">动位移</p>
          <img src="../../../assets/images/noData.png" alt="" />
          <span>暂无数据</span>
      </div>

      
      <div class="chartMain">
        <div class="box box_column smallTableMain">
          <!-- <div class="color1">加速度</div>
          <div class="" id="myChart"></div>
          <div class="color1 mT20">动位移</div>
          <div class="" id="myChart1"></div> -->
          <!-- <p class="emColor">加速度</p> -->
          <!-- <p class="emColor">动位移</p> -->
          <!-- <div class="myChart" id="myChartDynamic1"></div> -->
        </div>
        <!-- <div class="box justify smallTableMain">
          <div class="flex1" id="myChart"></div>
          <el-table
              :data="msgList">
            <el-table-column label="辅助信息">
              <el-table-column width="122px" prop="name"></el-table-column>
              <el-table-column width="162px" prop="value"></el-table-column>
            </el-table-column>
          </el-table>
        </div> -->
      </div>
    </template>
  </div>
</template>

<script>
import echarts from "echarts";
import moment from "moment";
import CommonPagination from "../../../components/Pagination";

let option = {
  dataZoom: [
    {
      show: true,
      realtime: true,
      start: 0,
      end: 10,
      fillerColor: "rgba(47,50,51,1)"
    },
    {
      type: "inside",
      realtime: true,
      start: 0,
      end: 10
    }
  ],
  grid: {
    top: "30",
    left: "5%",
    right: "8",
    bottom: "60"
  },
  tooltip: {
    trigger: "axis"
  },
  // dataZoom: [
  //   {
  //     show: true,
  //     realtime: true,
  //     start: 40,
  //     end: 60,
  //     bottom:10,
  //     fillerColor:'rgba(47,50,51,1)'
  //   },
  //   {
  //     type: 'inside',
  //     realtime: true,
  //     start: 20,
  //     end: 85
  //   }
  // ],
  xAxis: {
    type: "category",
    axisLabel: {
      color: "#fff",
      fontSize: 14,
      rotate: 4,
      showMinLabel: false
    },
    axisLine: {
      lineStyle: {
        color: "#9D9D9D",
        type: "dashed"
      }
    },
    axisTick: {
      show: false
    },
    data: []
  },
  yAxis: [
    {
      type: "value",
      name: "作用效应",
      nameTextStyle: {
        color: "#fff",
        fontSize: 14
      },
      axisLabel: {
        color: "#fff",
        fontSize: 14
      },
      axisLine: {
        lineStyle: {
          color: "#9D9D9D"
        }
      },
      splitLine: {
        lineStyle: {
          color: "#9D9D9D",
          type: "dashed"
        }
      },
      axisTick: {
        show: false
      }
    },
    {
      type: "value",
      axisLine: {
        lineStyle: {
          color: "#9D9D9D"
        }
      },
      axisTick: {
        show: false
      }
    }
  ],
  series: [
    {
      name: "",
      data: [],
      type: "line",
      symbol: "none",
      itemStyle: {
        color: "#6DB5C7"
      },
      lineStyle: {
        color: "#6DB5C7",
        width: 1
      }
    }
  ]
};
let option1 = {
  dataZoom: [
    {
      show: true,
      realtime: true,
      start: 0,
      end: 10,
      fillerColor: "rgba(47,50,51,1)"
    },
    {
      type: "inside",
      realtime: true,
      start: 0,
      end: 10
    }
  ],
  grid: {
    top: "30",
     left: "5%",
    right: "8",
    bottom: "60"
  },
  // dataZoom: [
  //   {
  //     show: true,
  //     realtime: true,
  //     start: 40,
  //     end: 60,
  //     bottom:10,
  //     fillerColor:'rgba(47,50,51,1)'
  //   },
  //   {
  //     type: 'inside',
  //     realtime: true,
  //     start: 20,
  //     end: 85
  //   }
  // ],
  tooltip: {
    trigger: "axis"
  },
  xAxis: {
    type: "category",
    axisLabel: {
      color: "#fff",
      fontSize: 14,
      rotate: 4,
      showMinLabel: false
    },
    axisLine: {
      lineStyle: {
        color: "#9D9D9D",
        type: "dashed"
      }
    },
    axisTick: {
      show: false
    },
    data: []
  },
  yAxis: [
    {
      type: "value",
      name: "作用效应",
      nameTextStyle: {
        color: "#fff",
        fontSize: 14
      },
      axisLabel: {
        color: "#fff",
        fontSize: 14
      },
      axisLine: {
        lineStyle: {
          color: "#9D9D9D"
        }
      },
      splitLine: {
        lineStyle: {
          color: "#9D9D9D",
          type: "dashed"
        }
      },
      axisTick: {
        show: false
      }
    },
    {
      type: "value",
      axisLine: {
        lineStyle: {
          color: "#9D9D9D"
        }
      },
      axisTick: {
        show: false
      }
    }
  ],
  series: [
    {
      name: "",
      data: [],
      type: "line",
      symbol: "none",
      itemStyle: {
        color: "#86FFB4"
        // normal: {
        //   lineStyle: {
        //     color: "#86FFB4",
        //     width: 2,
        //     type: "dotted" //'dotted'虚线 'solid'实线
        //   }
        // }
      },
      lineStyle: {
        width: 2,
        type: "dotted" //'dotted'虚线 'solid'实线
      }
    }
  ]
};

import mixin from "./mixin";
export default {
  name: "temAnalysis",
  components: {
    CommonPagination
  },
  mixins: [mixin],
  data() {
    return {
      showNoData1:false,
      showNoData2:false,
      hiddenTotal: true,
      tableData: [],
      pageSize: 5,
      currentPage: 1,
      total: 0,
      loading: false,
      ruleForm: { period: 1, range: 1, quota: null, startTime: "", endTime: "" },
      rules: {},
      rangeList: [
        { name: "结构整体作用效应", id: 1 },
        { name: "结构局部作用效应", id: 2 }
      ],
      quotaList: [
        { name: "结构整体变形", id: 1 },
        { name: "位移及转角", id: 2 },
        { name: "结构整体振动", id: 3 }
      ],
      periodList: [],
      isShowMain: false,
      targetName: "",
      targetUnit: "",
      targetId: "",
      msgList: [
        { value: "", name: "最大值" },
        { value: "", name: "最小值" }
      ],
      lineSize: 20000, //显示多少条 5000
      dataType: 19
    };
  },
  mounted() {
    this.changeTimeDay(1);
    this.getPeriodList();
    // this.getQuotaList()
    this.getQuotaTypeList();
    this.querryData();
  },
  methods: {
    changeTimeDay(n) {
      var day = new Date();
      var day1 = day.getTime(); //获得当前时间戳
      var day2 = day1 - n * 24 * 60 * 60 * 1000;
      var start = moment(day2).format("YYYY-MM-DD HH:mm:ss");
      var end = moment(day).format("YYYY-MM-DD HH:mm:ss");

      this.ruleForm.startTime = start;
      this.ruleForm.endTime = end;
    },

    tableRowClassName({ row, rowIndex }) {
      //条纹变色
      if (rowIndex % 2 == 1) {
        return "hui1";
      } else {
        return "lv1";
      }
    },

    clearTable() {
      this.isShowMain = false;
      this.tableData = [];
    },
    getQuotaList() {
      let params = {};
      params.effectClassify = this.ruleForm.range;
      this.$axios.get(`${this.baseURL}base/baseTargetType/effect`, { params }).then((res) => {
        res = res.data;
        if (res.errCode === "200") {
          this.quotaList = res.data;
          this.ruleForm.quota = res.data[0].id;
        } else {
          this.$message.error(res.errMsg);
        }
      });
    },
    changeType() {
      this.clearTable();
      // this.getQuotaList()
      this.getQuotaTypeList();
    },
    querryData() {
      this.handleCurrentChange(1);
    },
    look(target) {
      console.log(target);
      this.targetId = target.id;
      this.targetName = target.code;
      this.targetUnit = target.unit;
      this.isShowMain = true;
      this.getLineData();
      this.getLineData1();
    },
    initEcharts(time, data) {
      let myChart = echarts.init(document.getElementById("myChartDynamic"));
      //   option.yAxis[0].name=this.targetUnit
      // option.yAxis[0].name = "mm/ss";  //old
      // myChart.setOption(option);  //old
      var name = "加速度(mm/ss)"
      var lineColor = '#54a6ff';
      this.$chart2.line1('myChartDynamic', time, data, name, lineColor);

    },
    initEcharts1(time, data) {
      let myChart1 = echarts.init(document.getElementById("myChartDynamic1"));
      //   option.yAxis[0].name=this.targetUnit
      // option1.yAxis[0].name = "动位移(mm)";
      var name = "动位移(mm)";
      var lineColor = '#86eaff'
      this.$chart2.line1('myChartDynamic1', time, data, name, lineColor);
      // myChart1.setOption(option1);
      var chart1 = echarts.init(document.getElementById('myChartDynamic'))
      var chart2 = echarts.init(document.getElementById('myChartDynamic1'))
      echarts.connect([chart1, chart2])
    },

    handleCurrentChange(val) {
      this.currentPage = val;
      this.getTable();
    },
    getPeriodList() {
      let periodList = [];
      for (let i = 0; i < 12; i++) {
        let item = {
          id: i + 1,
          name: i + 1 + "个月"
        };
        periodList.push(item);
      }
      this.periodList = periodList;
    },
    getLineData() {
      // 请求图表
      let targetId = this.targetId;
      // targetId = 1
      let params = {
        targetId: this.targetId,
        startTime: this.ruleForm.startTime + ":00",
        endTime: this.ruleForm.endTime + ":00",
        sort: 0,
        size: this.lineSize
      };
      this.loadingPage = this.$loading({
        lock: true,
        text: "正在加载...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      this.$axios.get(`${this.baseURL}data/history/ddis/origin/${targetId}`, { params }).then((res) => {
        this.loadingPage.close();
        res = res.data;
        console.log(res);
        let data = res.data;
        if(data && data.length){
          this.showNoData1 = false
        }else{
          this.showNoData1 = true
        }
        let xData = [];
        let yData = [];
        if (res.errCode === "200") {
          console.log(data);
          for (let item of data) {
            // xData.push(item.time)
            //  xData.push(new Date(item.time).Format('hh:mm:ss'))
            xData.push(moment(item.time).format("YYYY-MM-DD hh:mm:ss"));
            yData.push(item.data[0]);
          }
          console.log("yData", yData);
          // this.msgList[0].value= yData.max.toFixed(3)
          // this.msgList[1].value= yData.min.toFixed(3)

          // option.xAxis.data = xData;
          // option.series[0].data = yData;
        } else {
          this.$message.error(res.errMsg);
        }

        this.initEcharts(xData, yData);
      });
    },

    getLineData1() {
      // 请求图表1
      let targetId = this.targetId;
      // targetId = 1
      let params = {
        targetId: this.targetId,
        startTime: this.ruleForm.startTime + ":00",
        endTime: this.ruleForm.endTime + ":00",
        sort: 0,
        size: this.lineSize
      };
      this.loadingPage = this.$loading({
        lock: true,
        text: "正在加载...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      this.$axios.get(`${this.baseURL}data/history/ddis/${targetId}`, { params }).then((res) => {
        this.loadingPage.close();
        res = res.data;
        console.log(res);
        let data = res.data;
        if(data && data.length){
          this.showNoData2 = false
        }else{
          this.showNoData2 = true
        }
        let xData = [];
        let yData = [];
        if (res.errCode === "200") {
          console.log(data);
          for (let item of data) {
            // xData.push(item.time)
            //  xData.push(new Date(item.time).Format('hh:mm:ss'))
            xData.push(moment(item.time).format("YYYY-MM-DD hh:mm:ss"));
            yData.push(item.data[0]);
          }
          console.log("yData", yData);
          // this.msgList[0].value= yData.max.toFixed(3)
          // this.msgList[1].value= yData.min.toFixed(3)

          option1.xAxis.data = xData;
          option1.series[0].data = yData;
        } else {
          this.$message.error(res.errMsg);
        }

        this.initEcharts1(xData, yData);
      });
    },

    getTable() {
      let params = {
        projectId: this.$store.state.projectId,
        // type:this.ruleForm.quota,
        type: "1008",
        current: this.currentPage,
        size: this.pageSize
      };
      this.$axios.get(`${this.baseURL}setttings/settingsDataAnalysis/type/${this.currentPage}/${this.pageSize}`, { params }).then((res) => {
        res = res.data;
        let data = res.data;
        console.log(res);
        if (res.errCode === "200") {
          this.tableData = data.records;
          if (this.tableData.length) {
            this.look(this.tableData[0].target);
          }
          this.total = data.total;
        } else {
          this.$message.error(res.errMsg);
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
.main {
  ::v-deep .el-form {
    margin-top: 24px;

    .el-form-item__label {
      font-size: 16px;
      font-weight: 500;
      color: #fff;
      text-align: left;
      line-height: 30px;
      width: 96px;
      padding: 0;
    }

    .smallLabel {
      .el-form-item__label {
        width: 80px;
        font-weight: 300;

        &.wid96 {
          width: 96px;
        }
      }
    }

    .el-form-item {
      margin-bottom: 0;
      display: flex;

      &.mB20 {
        margin-bottom: 20px;
      }

      .el-form-item__content {
        margin-left: 0 !important;
        line-height: 30px;
      }
    }

    .line {
      padding: 0 10px;
    }

    .typeSelect {
      .el-input {
        width: 200px;
      }
    }

    .el-input {
      width: 134px;
      height: 30px;

      .el-input__inner {
        font-size: 16px;
        color: #fff;
      }

      .el-select__caret {
        line-height: 30px;
      }

      &.el-date-editor {
        .el-input__inner {
          padding: 0 0 0 8px;
        }

        .el-icon-date {
          display: none;
        }
      }
    }
  }

  ::v-deep .el-radio-group {
    .el-radio__inner {
      width: 16px;
      height: 16px;
      background-color: transparent;
      border: 1px solid #787878;

      &:after {
        width: 6px;
        height: 6px;
      }
    }

    .el-radio__label {
      color: #fff;
      font-size: 16px;
    }
  }

  .pageMain {
    margin-top: 20px;
  }

  .title {
    display: block;
    margin-bottom: 10px;
    // position: absolute;
    // top: -30px;
  }
  .chartMain {
    position: relative;
    padding-top: 17px;

  }

  // #myChart {
  //   width: 97%;
  //   height: 280px;
  //   overflow: hidden;
  //   margin: auto;
  // }

  // #myChart1 {
  //   width: 97%;
  //   height: 280px;
  //   overflow: hidden;
  //   margin: auto;
  // }
  .myChart {
    width: 100%;
    height: 200px;
    overflow: hidden;
    // background: rgba($color: #FFFFFF, $alpha: 0.05);
    // border-radius: 24px;
  }
  ::v-deep .is-group.has-gutter {
    tr:nth-child(2) {
      display: none;
    }
  }
}

::v-deep .el-form-item__content {
  width: 180px;
}

::v-deep .el-date-editor .el-input {
  width: 180px;
}

::v-deep .el-date-editor .el-input__inner {
  width: 180px;
}

::v-deep .el-date-editor .el-input__icon {
  display: none;
}

.color1 {
  font-size: 22px;
  color: #dbf804;
  margin-bottom: 16px;
}

::v-deep .hui1 {
  background: #091d46 !important;
}

::v-deep .lv1 {
  background: #0c265a !important;
}

.echartsBox{
  // background: #04153F;
  border-radius: 2px;
  padding: 11px;
}
.noDataMain {
  padding-top: 40px;
  padding-bottom: 40px;
}
.title {
  position: relative;
  left: 14px;
  font-size: 16px;
  margin-bottom: 10px;
  display: inline-block;
}
.title::before {
        content: "";
        position: absolute;
        top: 4px;
        left: -12px;
        width: 4px;
        height: 12px;
        opacity: 0.58;
        background: #72d6f3;
      }
</style>
